<template>
  <div>
    <page-header heading="Refund Policy"></page-header>
    <div class="container">
      <section class="mt-5">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">General</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">At Kips Virtual, we are committed to providing high-quality online courses to help you achieve your educational goals. We understand that circumstances may change, and you may need to request a refund. This refund policy outlines the guidelines and procedures for requesting a refund for our online courses.</div>
            </div>
          </div>
        </div>
      </section>

      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Eligibility for Refund:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <div class="faq-section-sub-heading">Full Refunds:</div>
                    <p class="faq-section-paragraph">You are eligible for a full refund if you request it within 3 days of the course start date. This ensures that you have ample time to explore the course content and evaluate its suitability for your needs.</p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Partial Refunds:</div>
                    <p class="faq-section-paragraph">If you decide to request a refund after the initial 3-day period but before 7 days from the course start date, you may be eligible for a partial refund. The amount refunded will be based on the time that has passed since the course start date and your engagement with the course materials.</p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Ineligibility for Refund:</div>
                    <p class="faq-section-paragraph">Refunds will not be provided for requests made after 7 days from the course start date or for courses that have been completed or substantially accessed. Additionally, any course materials or certificates received must be returned or deleted upon refund approval. Eligibility Criteria is subject to further approvals for valid reasons.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Refund Process:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <p class="faq-section-paragraph">To initiate a refund request, please contact our customer support team at refunds@kipsvirtual.com. In your refund request, include your full name, email address, course name, and order number.</p>
                  </li>
                  <li>
                    <p class="faq-section-paragraph">Our customer support team will review your request and verify your eligibility for a refund based on the criteria mentioned in section 1.</p>
                  </li>
                  <li>
                    <p class="faq-section-paragraph">If your refund request is approved, the refund will be processed using the payment method as deemed possible. Please allow up to 20 business days for the refund to appear in your account.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Course Access:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <p class="faq-section-paragraph">Upon refund approval, your access to the course and all associated materials will be revoked.</p>
                  </li>
                  <li>
                    <p class="faq-section-paragraph">In case you have already downloaded or received any course material, you will be required to delete or return it as a condition of receiving the refund.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Exceptional Circumstances:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <p class="faq-section-paragraph">In cases of documented medical emergencies, personal hardships, or technical issues preventing access to the course, we may consider exceptions to the refund policy. Such requests will be reviewed on a case-by-case basis and require appropriate documentation.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Modifications to Refund Policy:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <p class="faq-section-paragraph">Kips Virtual reserves the right to modify or update this refund policy at any time. Any changes will be posted on our website and will apply to refund requests made after the date of modification.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-5">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Contact Us:</div>
              <div class="green-border-line"></div>
              <p class="faq-section-paragraph">For any further questions or clarifications regarding our refund policy, please contact our customer support team at support@kipsvirtual.com.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <appFooter />
  </div>
</template>
<script>
import pageHeader from "../components/pageHeader.vue";
import appFooter from "../layout/landing/appFooter.vue";
export default {
  components: {
    pageHeader,
    appFooter,
  },
  metaInfo: {
    title: "Refund Policy | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
