<template>
  <div>
    <page-header heading="Privacy Policy"></page-header>
    <div class="container">
      <section class="mt-5">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">General</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">Welcome to KIPS Virtual. We are committed to safeguarding your privacy
                and
                ensuring the security of your personal information. This Privacy Policy outlines how we collect, use,
                disclose, and protect the information you provide on our website/applications and through our services.
              </div>
              <div class="faq-section-paragraph">By accessing or using our website/applications and services, you agree to
                the terms of this Privacy Policy. If you do not agree with the terms of this policy, please do not use our
                website/applications or services.</div>
            </div>
          </div>
        </div>

      </section>

      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Information We Collect:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We collect both personally identifiable information (PII) and
                non-personally identifiable information from our users. The information we collect includes but is not
                limited to:
                <ul>
                  <li>
                    <div class="faq-section-sub-heading">Personal Information</div>
                    <p class="faq-section-paragraph"> Your
                      name,
                      email address, contact number,
                      mailing address (in some cases), and other information necessary to create and manage your account.
                    </p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Payment Information</div>
                    <p class="faq-section-paragraph">
                      Debit/credit
                      card details, billing address, and other payment-related information necessary to process
                      payments for our courses.</p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Usage Data</div>
                    <p class="faq-section-paragraph"> Information about
                      how
                      you use our website and services, including browsing
                      history, session duration, and interactions.</p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Technical Information </div>
                    <p class="faq-section-paragraph"> Device
                      information, IP addresses, browser type, and other technical data
                      collected through cookies and similar technologies.</p>
                  </li>
                  <li>
                    <div class="faq-section-sub-heading">Communication Data </div>
                    <p class="faq-section-paragraph">Information
                      you provide when communicating with us through email, chat, or
                      other communication channels.</p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">How We Use Your Information</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We use the collected information for various purposes, including but not
                limited to:
                <ul>
                  <li>Providing and improving our services and courses</li>
                  <li>Processing payments and managing your account</li>
                  <li>Sending you important notifications, updates, and promotional material</li>
                  <li>Analyzing usage patterns to enhance user experience and optimize our website/applications</li>
                  <li>Responding to your inquiries and providing customer support</li>
                  <li>Complying with legal obligations and preventing fraudulent activities</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Data Security</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We implement industry-standard security measures to protect your
                information from unauthorized access, loss, misuse, or alteration. However, no data transmission over the
                internet or electronic storage method is completely secure, so we cannot guarantee absolute security.
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Data Disclosure</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We do not sell, trade, or rent your personal information to third parties
                for marketing purposes. We may share your information with trusted third-party service providers who
                assist
                us in operating our website/applications and delivering our services. These service providers are
                contractually bound to use your information solely for the purpose of providing the requested services.
                We may also disclose your information if required by law or to protect our rights, privacy, safety, or
                property, or those of others.

              </div>
            </div>
          </div>
        </div>

      </section>

      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Cookies</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We use cookies and similar tracking technologies to collect information
                about your interactions with our website/applications. You can manage your cookie preferences through your
                browser settings.
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Your Choices</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">You have the right to access, update, correct, or delete your personal
                information. You can also choose to unsubscribe from marketing communications at any time. Please contact
                us
                at 042111547775 to exercise your rights.
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Children's Privacy</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">Our services are intended for users aged 13 and above. We do not
                knowingly
                collect personal information from children under 13 years of age. If you believe a child has provided us
                with their information, you can contact us to discuss.
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Changes to Privacy Policy</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">We may update our Privacy Policy from time to time. Changes will be
                posted
                on this page, and the "Effective Date" at the top will reflect the latest revision.
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Contact Us</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">If you have any questions, concerns, or requests related to this Privacy
                Policy, please contact us at support@kipsvirtual.com or call at our UAN: 042111547775

              </div>
            </div>
          </div>
        </div>

      </section>
      <div class="faq-section-paragraph mb-5 mt-3 ">By using our website/applications and services, you acknowledge that
        you
        have
        read and understood this
        Privacy Policy and agree to its terms.

        <br>

      </div>
    </div>
    <appFooter />
  </div>
</template>
<script>
import pageHeader from "../components/pageHeader.vue";
import appFooter from "../layout/landing/appFooter.vue";
export default {
  components: {
    pageHeader,
    appFooter,
  },
  metaInfo: {
    title: "Privacy Policy | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
