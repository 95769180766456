<template>
  <div>
    <page-header heading="Terms and Conditions"></page-header>
    <div class="container">
      <section class="mt-5">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">General</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">These terms and conditions ("Terms") govern your use of KIPS Virtual (the
                "Website/Application") and online courses (the "Courses"). By accessing and using the Website/Application
                or enrolling in any of the Courses, you agree to comply with these Terms. Please read these Terms
                carefully before using our services. </div>
            </div>
          </div>
        </div>
      </section>

      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">User Accounts and Registration:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph"> You may need to create an account to access certain features of the
                      Website/Application or enroll in Courses. You are responsible for maintaining the confidentiality of
                      your account information and for all activities that occur under your account.
                    </p>
                  </li>
                  <li>
                    <!-- <div class="faq-section-sub-heading">Payment Information</div> -->
                    <p class="faq-section-paragraph">You must provide accurate and complete information when creating your
                      account and enrolling in Courses. You agree to keep your account information up-to-date. </p>
                  </li>
                  <li>
                    <!-- <div class="faq-section-sub-heading">Usage Data</div> -->
                    <p class="faq-section-paragraph"> You are solely responsible for any actions taken through your
                      account, and you agree to notify us of any unauthorized use or security breach immediately..</p>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Course Enrollment:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph"> When you enroll in a Course, you agree to abide by the terms and
                      conditions specific to that Course, including any prerequisites, fees, and attendance requirements.
                    </p>
                  </li>
                  <li>
                    <!-- <div class="faq-section-sub-heading">Payment Information</div> -->
                    <p class="faq-section-paragraph">Access to Courses is granted for the duration specified upon
                      enrollment. Any violation of these Terms or Course-specific terms may result in the termination of
                      your access to the Course without refund. </p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Intellectual Property:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">All content on the Website/Application, including text, images,
                      videos, and course materials, is protected by copyright and other intellectual property laws. You
                      may only use the content for personal, non-commercial purposes and may not reproduce, distribute, or
                      modify the content without our prior written consent.
                    </p>
                  </li>
                  <li>
                    <!-- <div class="faq-section-sub-heading">Payment Information</div> -->
                    <p class="faq-section-paragraph">By enrolling in a Course, you are granted a limited, non-exclusive,
                      non-transferable license to access and use the course material solely for your personal educational
                      purposes.</p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Refund Policy:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">Our refund policy is outlined separately and governs the terms and
                      conditions for requesting a refund for Courses. Please refer to our Refund Policy for more details.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Code of Conduct: </div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">You agree to conduct yourself in a respectful and ethical manner
                      while using the Website/Application and participating in Courses. You may not engage in any conduct
                      that disrupts the learning experience of others or violates any applicable laws or regulations.
                    </p>
                  </li>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">You may not share, distribute, or otherwise make available any Course
                      material to individuals who have not enrolled in the Course.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Privacy Policy:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">Our Privacy Policy outlines how we collect, use, and protect your
                      personal information. By using the Website/Application and enrolling in Courses, you consent to the
                      practices described in our Privacy Policy.
                    </p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Termination of Access:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">We reserve the right to suspend or terminate your access to the
                      Website/Application or Courses if you violate these Terms or engage in any conduct that we deem
                      inappropriate or harmful.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Disclaimer of Warranties:</div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">The Website/Application and Courses are provided on an "as-is" and
                      "as-available" basis. We do not make any warranties, express or implied, regarding the accuracy,
                      reliability, or suitability of the content or services.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Limitation of Liability: </div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">KIPS Virtual shall not be liable for any direct, indirect,
                      incidental, special, or consequential damages arising out of or in connection with your use of the
                      Website/Application or Courses.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <div class="faq-section-heading">Modifications to Terms: </div>
              <div class="green-border-line"></div>
              <div class="faq-section-paragraph">
                <ul>
                  <li>
                    <!-- <div class="faq-section-sub-heading"></div> -->
                    <p class="faq-section-paragraph">KIPS Virtual reserves the right to modify or update these Terms at
                      any time. Any changes will be effective upon posting on the Website/Application. Continued use of
                      the Website/Application or enrollment in Courses after such changes signifies your acceptance of the
                      modified Terms.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-5">
        <div class="container general-faq-cntnr">
          <div class="row faq-row">
            <div class="">
              <p class="faq-section-paragraph">These Terms and Conditions were last updated on August 15, 2023. If you
                have any questions or concerns about these Terms, please contact us at support@kipsvirtual.com.</p>
            </div>
          </div>
        </div>
      </section>

    </div>
    <appFooter />
  </div>
</template>
<script>
import pageHeader from "../components/pageHeader.vue";
import appFooter from "../layout/landing/appFooter.vue";
export default {
  components: {
    pageHeader,
    appFooter,
  },
  metaInfo: {
    title: "Terms and Conditions | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
